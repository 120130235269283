import { graphql } from "gatsby";
import { useContext } from "react";
import BlogList from "../components/BlogList";
import Footer from "../components/Footer";
import Headline from "../components/Headline";
import LayoutStyles from "../components/layout";
import MainContent from "../components/mainContent";
import Navbar from "../components/Navbar";
import SEO from "../components/SEO";
import ThemeContext from "../contexts/ThemeContext";
import GlobalStyles from "../styles/GlobalStyles";

export default function BlogsPage({ data }) {
  const { theme } = useContext(ThemeContext);
  const blogs = data?.allMarkdownRemark?.edges;

  return (
    <LayoutStyles>
      <GlobalStyles theme={theme} />
      <SEO
        title="Blogs | A collection of Software Development articles"
        titleTemplate={data?.site?.siteMetadata?.title}
        description={data?.site?.siteMetadata?.description}
      />
      <Navbar />
      <Headline
        title="Blogs"
        subtitle="Learnings, interview materials and templates"
      />
      <MainContent>
        <BlogList blogs={blogs} />
      </MainContent>
      <Footer />
    </LayoutStyles>
  );
}

export const pageQuery = graphql`
  query BlogsPageQuery {
    site {
    siteMetadata {
      title
      description
    }
  }
  allMarkdownRemark(filter: { frontmatter: { isBlog: { eq: true } } }
    sort: {order: DESC, fields: frontmatter___date}) {
    edges {
      node {
        id
        html
        frontmatter {
          description
          title
          slug
          date(formatString: "D MMMM, YYYY")
        }
      }
    }
  }
}
`;
