import styled from "styled-components";
import { glassmorphism, shadow } from "../styles/GlobalStyles";

const BlogStyles = styled.article`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--secondary);
  padding: 1rem;
  border-radius: 5px;
  background-color: var(--primary);
  border: 1px solid rgba(255, 255, 255, 0.18);

  ${shadow}

  &:hover {
    ${glassmorphism}
    transition: all var(--transition-speed);
  }

  a {
    color: var(--secondary) !important;
  }

  .blog-details {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    flex: 1;
    margin-left: 1rem;
    height: 100%;
  }

  .blog-details h1 {
    margin: 0;
  }

  .blog-doc-emoji {
    margin-right: 0.5rem;
  }

  .blog-date {
    color: var(--other);
  }
`;

export default function BlogCard({ blog }) {
  const { frontmatter } = blog;

  return (
    <BlogStyles>
      <a href={frontmatter.slug}>
        <div className="blog-details">
          <h1><span role="img" aria-label="document emoji" className="blog-doc-emoji">📄</span>{frontmatter.title} </h1>
          <p className="blog-date">{frontmatter.date}</p>
          <p>{frontmatter.description}</p>
        </div>
      </a>
    </BlogStyles>
  )
}
