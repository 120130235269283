import styled from "styled-components";
import BlogCard from "./BlogCard";

const BlogListStyles = styled.section`
  width: 100%;
  display: grid;
  gap: 2rem 2rem;
  grid-template-columns: repeat(auto-fill, minmax(var(--column-width), 1fr));
  margin: 3rem 0;
`;

export default function BlogList({blogs = []}) {
  return (
    <BlogListStyles>
      {blogs.map(({ node }) => <BlogCard key={node.id} blog={node} />)}
    </BlogListStyles>
  )
}
